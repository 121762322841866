@import url(https://fonts.googleapis.com/css?family=Open+Sans);
.root {
  font-family: "Commissioner", sans-serif;
  font-style: normal;
}

.root {
  font-family: "Commissioner", sans-serif;
  font-style: normal;
}

/* .ql-container.ql-snow {
  border: none;
} */

.dx-htmleditor-content img {
  vertical-align: middle;
  padding-right: 10px;
}

.dx-htmleditor-content table {
  width: 50%;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

:root {
  --nav-bar-height: 64px;
  --textbox-height: 60px;
  --textbox-helper-height: 200px;
  --chat-header-height: 40px;
  --active-chat-info-height: 50px;
  --softphone-header-height: 30px;
}
.App {
  text-align: center;
}

::-webkit-input-placeholder {
  color: #9f9dad;
}

::placeholder {
  color: #9f9dad;
}

body {
  overflow: hidden;
}


[aria-placeholder]:empty:before {
  content: attr(aria-placeholder);
  font-style: italic;
  color: black;
}

input:focus,
textarea:focus {
  outline: none;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  cursor: pointer;
  border-radius: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: orange;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgoldenrod;
}

@-webkit-keyframes pulse {
  0% {
    color: lightgray;
  }

  70% {
    -webkit-filter: blackZ;
            filter: blackZ;
  }

  100% {
    -webkit-filter: gray;
            filter: gray;
  }
}

@keyframes pulse {
  0% {
    color: lightgray;
  }

  70% {
    -webkit-filter: blackZ;
            filter: blackZ;
  }

  100% {
    -webkit-filter: gray;
            filter: gray;
  }
}

.swal2-container {
  z-index: 10000 !important;
}

body {
	margin: 0;
	font-family: Open Sans !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

