:root {
  --nav-bar-height: 64px;
  --textbox-height: 60px;
  --textbox-helper-height: 200px;
  --chat-header-height: 40px;
  --active-chat-info-height: 50px;
  --softphone-header-height: 30px;
}
.App {
  text-align: center;
}

::placeholder {
  color: #9f9dad;
}

body {
  overflow: hidden;
}


[aria-placeholder]:empty:before {
  content: attr(aria-placeholder);
  font-style: italic;
  color: black;
}

input:focus,
textarea:focus {
  outline: none;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  cursor: pointer;
  border-radius: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: orange;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgoldenrod;
}

@keyframes pulse {
  0% {
    color: lightgray;
  }

  70% {
    filter: blackZ;
  }

  100% {
    filter: gray;
  }
}

.swal2-container {
  z-index: 10000 !important;
}
